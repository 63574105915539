@import "bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
// customize some Bootstrap variables
$theme-colors: (
  "primary": #0074d9,
  "info": #6bb3dd,
  "success": #3ac2a7,
  "warning": #ece57f,
  "danger": #b73838,
  "light": rgba(179, 164, 171, 0.15)
);

// $enable-rounded: false;


$border-radius: .15rem;
$card-border-radius: .15rem;
$btn-border-radius: .15rem;


// .card-header {
//   background-color: rgba(179, 164, 171, 0.15) !important;
// }

$logo-start-color: #f85931;

.text-logo-start {
    color:$logo-start-color !important;
}

.bg-logo-start {
    background-image: linear-gradient(to right, $logo-start-color, #b73838) !important;
    // background-color:$logo-start-color;
}

$navbar-dark-color: rgba(white, .70);
$navbar-dark-hover-color: rgba(white, .85);
$navbar-dark-active-color: rgba(white, 1);

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";

