body {
    background-color: lightgray;
}

.partner {
    text-align: center;
}

.card-body-without-side-padding {
    padding: 1.25rem 0 !important;;
}

.custom-card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 1.25rem;
}

.w-98 {
    width: 98% !important;
}

.jumbotron-partners {
    background-image: url("../img/partnerzy-multikupon.jpg");
    background-size: cover;
    text-shadow: rgba(0, 0, 0, 0.5) 0.1em 0.1em 0.3em;
}